body {
    margin: 0;
    padding: 0;
    background-image: url('../../public/images/antena.gif'); 
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white; /* Color del texto */
  }

 footer {
    font-size: 0.6em;
    margin-top: 20px;
    margin:0;
    text-align: right;
    font-size: 8px;
  }

@media screen and (max-width: 768px) {
  footer {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 480px) {
  footer {
    font-size: 0.6em;
  }
}

  .logo {
    width: 200px; /* Ajusta el ancho del logo según tus necesidades */
    height: auto; /* Esto mantiene la proporción original del logo */
    margin-bottom: 20px; /* Agrega espacio debajo del logo */
  }
